<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <v-col cols="12">
        <div>
          <confirmation-dialog
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
            :dialog="dialogDelete"
            :dialogHeadline="deleteConfirmationHeadline"
          ></confirmation-dialog>
          <v-data-table
            :loading="loading"
            item-key="id"
            class="elevation-0 pb-4 pt-4 members_list_table"
            :headers="headers"
            :items="news"
            :expanded.sync="expanded"
            show-expand
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('news.search')"
                  @input="searchNews(1)"
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="manageNews"
                  color="primary"
                  dark
                  large
                  dense
                  elevation="4"
                  @click="createUpdateItem"
                >
                  {{ $t("news.addNews") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" v-html="item.description"></v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.updated_at="{ item }">
              {{ item.updated_at | DateTimezoneFilter }}
            </template>
            <template v-if="manageNews" v-slot:item.actions="{ item }">
              <v-btn
                class="mr-3 green-color"
                color="green"
                :ripple="false"
                icon
              >
                <v-icon medium @click="createUpdateItem(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
              <v-btn color="error" class="mr-3 red-color" :ripple="false" icon>
                <v-icon
                  medium
                  @click="
                    selectedNews = item;
                    dialogDelete = true;
                  "
                >
                  mdi-delete</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                class="mr-3 green-color"
                :ripple="false"
                icon
              >
                <v-icon medium @click="repostItem(item)"> mdi-refresh</v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize(1)"> Reset </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="news.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { MANAGE_NEWS } from "@/constants/permission";
import _ from "lodash";
import { showSnackbar } from "../eventbus/action.js";
export default {
  name: "News",
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      manageNews: false,
      expanded: [],
      page: 1,
      itemsPerPage: 50,
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: this.$t("news.id"),
          align: "start",
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("news.title"),
          value: "title",
          width: "36%",
        },
        {
          text: this.$t("news.seen"),
          value: "unread_count",
          width: "12%",
        },
        {
          text: this.$t("news.unseen"),
          value: "read_count",
          width: "12%",
        },
        {
          text: this.$t("news.last_updated"),
          value: "updated_at",
          width: "15%",
          sortable: false,
        },
        {
          text: this.$t("news.actions"),
          value: "actions",
          width: "15%",
          sortable: false,
        },
        {
          text: "",
          value: "data-table-expand",
          width: "5%",
        },
      ],
      selectedNews: {
        id: null,
        title: null,
        description: "",
        media_ids: [],
      },
      payload: {},
      defaultItem: {
        id: null,
        title: null,
        description: "",
        media_ids: [],
      },
      loading: false,
      deleteConfirmationHeadline: this.$t("news.deleteHeadline"),
    };
  },
  computed: {
    ...mapGetters({
      news: "news/getNewsList",
      meta: "news/getMeta",
      user: "user/getUser",
    }),
  },
  mounted() {
    this.initialize(1);
  },

  methods: {
    ...mapActions({
      getNews: "news/getNewsList",
      updateNews: "news/updateNews",
      deleteNews: "news/deleteNews",
    }),

    async initialize(page) {
      this.page = page;
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageNews = userPermission.includes(MANAGE_NEWS);
      this.loading = true;
      try {
        await this.getNews({
          search: this.search,
          page: this.page,
          per_page: this.itemsPerPage,
          ...this.payload,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    searchNews: _.debounce(function (page) {
      this.initialize(page);
    }, 500),

    createUpdateItem(item = null) {
      this.selectedNews = item
        ? _.cloneDeep(item)
        : _.cloneDeep(this.defaultItem);
      if (this.selectedNews.medias && this.selectedNews.medias.length > 0) {
        let media_ids = this.selectedNews.medias.map(function (value) {
          return value.id;
        });
        this.selectedNews.media_ids = media_ids;
      }
      this.$router.push({
        name: "CreateAndEditNews",
        params: { selectedNewsData: this.selectedNews },
      });
    },

    async repostItem(item) {
      this.loading = true;
      try {
        if (item.medias && item.medias.length > 0) {
          let media_ids = item.medias.map(function (value) {
            return value.id;
          });
          item.media_ids = media_ids;
        }
        await this.updateNews(item);
        this.initialize(this.page);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        await this.deleteNews({
          id: this.selectedNews.id,
        });
        this.dialogDelete = false;
        this.initialize(1);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
